<template>
 <div class="text-xs-center">
  <v-app style="justify-content: center;">
   
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-img  max-height="600"
                max-width="1500" 
                src="./assets/Social Provocateur X - final - Coming Soon.png">
        </v-img>
      </v-col>
    </v-row>
  </v-container>
  
  </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>



</style>
